<template>
  <div class="add-team">
    <Button
      theme="secondary"
      class="add-team__create-btn"
      @click="showCreateTeamModal = true"
    >
      <inline-svg
        class="add-team__create-icon"
        :src="require(`../assets/icons/Plus.svg`)"
        aria-label="Image"
      ></inline-svg>
      {{ $t("pages.settings.linkedTeams.child.createButton") }}
    </Button>
    <CreateTeam
      v-if="showCreateTeamModal"
      @close="showCreateTeamModal = false"
    ></CreateTeam>
  </div>
</template>

<script>
import Button from "@/components/Button";
import CreateTeam from "@/components/CreateTeam";

export default {
  components: {
    Button,
    CreateTeam
  },
  data: () => ({
    showCreateTeamModal: false
  })
};
</script>

<style lang="scss">
.add-team {
  min-height: 100vh;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__create-icon {
    margin-right: 0.5rem;
  }
}
</style>
